.green-pill {
  background-color: green;
  color: white;
}

.red-pill {
  background-color: red;
  color: white;
}

.pill {
  border-radius: 20px;
}
