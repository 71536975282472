.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pointer {
  cursor: "pointer";
}

.HContainer {
  display: flex;
  flex-direction: row !important;
}

.VContainer {
  display: flex;
  flex-direction: column !important;
}

.j-center {
  display: flex;
  justify-content: center !important;
}

.j-end {
  display: flex;
  justify-content: end !important;
}

.a-center {
  display: flex;
  align-items: center !important;
}

.MuiFormHelperText-root {
  color: red !important;
}

.b {
  font-weight: bold;
}

/* margin */
.no-margin {
  margin: 0 !important;
}

.icon-margin-r-15 {
  margin-right: 15px;
}

.w-icon-35 {
  width: 35px;
}

.w-icon-25 {
  width: 25px;
}

.w-icon-15 {
  width: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.vm-20 {
  margin: 20px 0;
}

.hm-15 {
  margin: 0 15px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.h-50 {
  height: 50px;
}

.pill {
  border-radius: 20px;
  color: #fff;
  font-weight: 700;
  margin: 2px 4px;
  width: fit-content;
  min-width: 60px;
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: center;
}

.pointer {
  cursor: pointer;
  margin: 0 20px;
}

.vm-15 {
  margin: 15px 0;
}

.login-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-title {
  margin-bottom: 20px;
  text-align: center;
}

.login-button-container {
  margin: 0 20px;
}

.login-forgot-link {
  margin-top: 10px;
  color: #1976d2;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
}

.login-forgot-link:hover {
  text-decoration: underline;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
